body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.scrollbar::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #f7f4ed;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #e0cbcb;
  border-radius: 100vh;
  border: 3px solid #f6f7ed;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #c0a0b9;
}

.ql-container {
  font-family: system-ui, -apple-system !important;
}

.custom-quill .ql-editor {
  line-height: 1.8 !important; /* Adjust the line height as per your requirement */
  font-size: medium;
}

.react-select__control {
  border: 1px solid #ccc; /* Add your desired border color here */
  box-shadow: none; /* Remove the box-shadow on focus */
}

.react-select__control:hover {
  border: 1px solid #ccc; /* Add your desired border color here */
}

.react-select__control:focus {
  border: 1px solid #ccc; /* Add your desired border color here */
  box-shadow: none; /* Remove the box-shadow on focus */
  outline: none; /* Remove the blue outline on focus */
}

/* Style the "Previous" and "Next" buttons */
.previous,
.next {
  padding: 8px;
  margin: 5px;
  background-color: transparent;
  color: rgb(41 37 36);
  border: 2px solid rgb(41 37 36);
  border-radius: 5px;
  cursor: pointer;
}

/* Style the page number buttons */
.notActivePage {
  padding: 10px;
  margin: 5px;
  background-color: transparent;
  color: rgb(41 37 36);
  border: 2px solid rgb(41 37 36);
  border-radius: 5px;
  cursor: pointer;
}

/* Style the active page button */
.activePage {
  padding: 10px;
  margin: 5px;
  background-color: rgb(41 37 36);
  color: #fff;
  border: 2px solid rgb(41 37 36);
  border-radius: 5px;
  cursor: pointer;
}

.breakLink {
  padding: 10px;
  margin: 5px;
  background-color: transparent;
  color: rgb(41 37 36);
  border: 2px solid rgb(41 37 36);
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .previous,
  .next {
    padding: 4px;
  }
  .notActivePage,
  .activePage,
  .breakLink {
    padding: 6px; /* Smaller padding for mobile devices */
  }
}

.carousel .thumbs-wrapper .thumb.selected {
  border: 1px solid #ccc; /* Change the border color for the selected thumbnail */
}

/* Style for the other thumbnails */
.carousel .thumbs-wrapper .thumb {
  border: 1px solid #ccc; /* Change the border color for the other thumbnails */
  /* Add any other styles as needed */
}

.carousel .thumbs-wrapper .thumb:hover {
  border: 2px solid blue; /* Change the border color when hovering over the other thumbnails */
  /* Add any other styles for hover as needed */
}

@tailwind base;
@tailwind components;
@tailwind utilities;
